<template>
  <div class="content">
    <div class="agreement" v-loading="loading">
      <div class="title">{{nowData.title}}</div>
      <div v-html="nowData.value" class="value"></div>
    </div>
  </div>
</template>

<script>
import {
  service_agreement
} from "@/api/signRegister.js"
export default {
  components: {

  },
  data () {
    return {
      serviceData: {},
      nowData: '',
      loading: false
    }
  },
  created () {
    let type = this.$route.params.type
    this.loading = true
    service_agreement({}).then(res => {
      this.serviceData = res.data
      if (type == 's_privacy_policy') {
        this.nowData = this.serviceData["children's_privacy_policy"]
      } else {
        this.nowData = this.serviceData[type]
      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.view-top{
  background-color: #fff !important;
}
.agreement{
  padding: 0 20px;
}
.title{
  font-size: 24px;
  text-align: center;
  line-height: 6rem;
}
.value{
  min-height: 19rem;
}
</style>